<template>
    <!-- Lead Form -->
    <!-- Section content start-->
    <section class="content-section">
        <!-- Progress bar-->
        <div class="progress-bar">
            <div class="progress" v-bind:style="{ width: progressWidth + '%'}"></div>
        </div>
        <!-- Form section-->
        <div class="form-section">
            <transition name="fade" mode="out-in" appear>
                <!-- Frage 1 Expresspaket-->
                <Express key="1" v-if="step == 1" />

                <!-- Frage 2 Finanzierung-->
                <Finance key="2" v-if="step == 2" />

                <!-- Frage 3 Wer stellt den Antrag-->
                <Applicant key="3" v-if="step == 3" />

                <!-- Frage 4 Zustimmung Ehepartner-->
                <Consent key="4" v-if="step == 4" />

                <!-- Frage 5 Daten des Ehemanns / Ehepartner 1-->
                <div class="section" key="5" v-if="step == 5">
                    <div class="section-title">
                        <div class="author"><img width="50" height="50" src="@/assets/images/rabier.png"></div>
                        <h3><span>Schritt 4</span> Daten des Ehemanns / Ehepartner 1</h3>
                        <p>Wir benötigen nun die Daten des Ehemannes /Ehepartner 1. Wenn Sie das Einkommen nicht kennen, kann diese Angabe nachgeholt werden.</p>
                    </div>
                    <div class="form-container form-data">
                        <div class="input-row">
                            <div class="wrap-input" :class="{ 'form-group--error': $v.formHusband.firstname.$error }">
                                <input type="text" 
                                id="input-husband-firstname" 
                                name="input-husband-firstname"
                                v-model="$v.formHusband.firstname.$model"
                                v-on:blur="$v.formHusband.firstname.$touch"
                                aria-describedby="input-husband-firstname-live-feedback"><span class="label-input" v-bind:class="{ filled : formHusband.firstname.length >= 1 }">Vorname *</span>
                                <div id="input-husband-firstname-live-feedback" v-if="!$v.formHusband.firstname.required" class="invalid-feedback">Dieses Feld ist ein Pflichtfeld. </div>
                            </div>
                        </div>
                        <div class="input-row">
                            <div class="wrap-input" :class="{ 'form-group--error': $v.formHusband.name.$error }">
                                <input type="text" 
                                id="input-husband-name" 
                                name="input-husband-name"
                                v-model="$v.formHusband.name.$model"
                                v-on:blur="$v.formHusband.name.$touch"
                                aria-describedby="input-husband-name-live-feedback"><span class="label-input" v-bind:class="{ filled : formHusband.name.length >= 1 }">Nachname *</span>
                                <div id="input-husband-name-live-feedback" v-if="!$v.formHusband.name.required" class="invalid-feedback">Dieses Feld ist ein Pflichtfeld. </div>
                            </div>
                        </div>
                        <div class="input-row">
                            <div class="wrap-input" :class="{ 'form-group--error': $v.formHusband.streetNo.$error }">
                                <input type="text" 
                                id="input-husband-streetNo" 
                                name="input-husband-streetNo"
                                v-model="$v.formHusband.streetNo.$model"
                                v-on:blur="$v.formHusband.streetNo.$touch"
                                aria-describedby="input-husband-streetNo-live-feedback"><span class="label-input" v-bind:class="{ filled : formHusband.streetNo.length >= 1 }">Straße / Nr. *</span>
                                <div id="input-husband-streetNo-live-feedback" v-if="!$v.formHusband.streetNo.required" class="invalid-feedback">Dieses Feld ist ein Pflichtfeld. </div>
                            </div>
                        </div>
                        <div class="input-row">
                            <div class="wrap-input" :class="{ 'form-group--error': $v.formHusband.postcodeCity.$error }">
                                <input type="text" 
                                id="input-husband-postcodeCity" 
                                name="input-husband-postcodeCity"
                                v-model="$v.formHusband.postcodeCity.$model"
                                v-on:blur="$v.formHusband.postcodeCity.$touch"
                                aria-describedby="input-husband-postcodeCity-live-feedback"><span class="label-input" v-bind:class="{ filled : formHusband.postcodeCity.length >= 1 }">Postleitzahl / Ort *</span>
                                <div id="input-husband-postcodeCity-live-feedback" v-if="!$v.formHusband.postcodeCity.required" class="invalid-feedback">Dieses Feld ist ein Pflichtfeld. </div>
                            </div>
                        </div>
                        <div class="input-row">
                            <div class="wrap-input" :class="{ 'form-group--error': $v.formHusband.nationality.$error }">
                                <input type="text" 
                                id="input-husband-nationality" 
                                name="input-husband-nationality"
                                v-model="$v.formHusband.nationality.$model"
                                v-on:blur="$v.formHusband.nationality.$touch"
                                @input="incrementChecked()"
                                aria-describedby="input-husband-nationality-live-feedback"><span class="label-input" v-bind:class="{ filled : formHusband.nationality.length >= 1 }">Nationalität *</span>
                                <div id="input-husband-nationality-live-feedback" v-if="!$v.formHusband.nationality.required" class="invalid-feedback">Dieses Feld ist ein Pflichtfeld. </div>
                            </div>
                        </div>
                        <div class="input-row">
                            <div class="wrap-input">
                                <input type="text" name="einkommen" v-model="formHusband.monthlyIncome"><span class="label-input" v-bind:class="{ filled : formHusband.monthlyIncome.length >= 1 }">Monatliches Nettoeinkommen (optional)</span>
                            </div>
                        </div>
                        <div class="notification">Bei unbekannt bitte leer lassen</div>
                    </div>
                </div>

                <!-- Frage 6 Daten der Ehefrau / Ehepartner 2-->
                <div class="section" key="6" v-if="step == 6">
                    <div class="section-title">
                        <div class="author"><img width="50" height="50" src="@/assets/images/rabier.png"></div>
                        <h3><span>Schritt 4</span> Daten der Ehefrau / Ehepartner 2</h3>
                        <p>Wir benötigen nun die Daten der Ehefrau /Ehepartner 2. Wenn Sie das Einkommen nicht kennen, kann diese Angabe nachgeholt werden.</p>
                    </div>
                    <div class="form-container form-data">
                        <div class="input-row">
                            <div class="wrap-input" :class="{ 'form-group--error': $v.formWife.firstname.$error }">
                                <input type="text" 
                                id="input-wife-firstname" 
                                name="input-wife-firstname"
                                v-model="$v.formWife.firstname.$model"
                                v-on:blur="$v.formWife.firstname.$touch"
                                aria-describedby="input-wife-firstname-live-feedback"><span class="label-input" v-bind:class="{ filled : formWife.firstname.length >= 1 }">Vorname *</span>
                                <div id="input-wife-firstname-live-feedback" v-if="!$v.formWife.firstname.required" class="invalid-feedback">Dieses Feld ist ein Pflichtfeld. </div>
                            </div>
                        </div>
                        <div class="input-row">
                            <div class="wrap-input" :class="{ 'form-group--error': $v.formWife.name.$error }">
                                <input type="text" 
                                id="input-wife-name" 
                                name="input-wife-name"
                                v-model="$v.formWife.name.$model"
                                v-on:blur="$v.formWife.name.$touch"
                                aria-describedby="input-wife-name-live-feedback"><span class="label-input" v-bind:class="{ filled : formWife.name.length >= 1 }">Nachname *</span>
                                <div id="input-wife-name-live-feedback" v-if="!$v.formWife.name.required" class="invalid-feedback">Dieses Feld ist ein Pflichtfeld. </div>
                            </div>
                        </div>
                        <div class="input-row">
                            <div class="wrap-input" :class="{ 'form-group--error': $v.formWife.streetNo.$error }">
                                <input type="text" 
                                id="input-wife-streetNo" 
                                name="input-wife-streetNo"
                                v-model="$v.formWife.streetNo.$model"
                                v-on:blur="$v.formWife.streetNo.$touch"
                                aria-describedby="input-wife-streetNo-live-feedback"><span class="label-input" v-bind:class="{ filled : formWife.streetNo.length >= 1 }">Straße / Nr. *</span>
                                <div id="input-wife-streetNo-live-feedback" v-if="!$v.formWife.streetNo.required" class="invalid-feedback">Dieses Feld ist ein Pflichtfeld. </div>
                            </div>
                        </div>
                        <div class="input-row">
                            <div class="wrap-input" :class="{ 'form-group--error': $v.formWife.postcodeCity.$error }">
                                <input type="text" 
                                id="input-wife-postcodeCity" 
                                name="input-wife-postcodeCity"
                                v-model="$v.formWife.postcodeCity.$model"
                                v-on:blur="$v.formWife.postcodeCity.$touch"
                                aria-describedby="input-wife-postcodeCity-live-feedback"><span class="label-input" v-bind:class="{ filled : formWife.postcodeCity.length >= 1 }">Postleitzahl / Ort *</span>
                                <div id="input-wife-postcodeCity-live-feedback" v-if="!$v.formWife.postcodeCity.required" class="invalid-feedback">Dieses Feld ist ein Pflichtfeld. </div>
                            </div>
                        </div>
                        <div class="input-row">
                            <div class="wrap-input" :class="{ 'form-group--error': $v.formWife.nationality.$error }">
                                <input type="text" 
                                id="input-wife-nationality" 
                                name="input-wife-nationality"
                                v-model="$v.formWife.nationality.$model"
                                v-on:blur="$v.formWife.nationality.$touch"
                                @input="incrementChecked()"
                                aria-describedby="input-wife-nationality-live-feedback"><span class="label-input" v-bind:class="{ filled : formWife.nationality.length >= 1 }">Nationalität *</span>
                                <div id="input-wife-nationality-live-feedback" v-if="!$v.formWife.nationality.required" class="invalid-feedback">Dieses Feld ist ein Pflichtfeld. </div>
                            </div>
                        </div>
                        <div class="input-row">
                            <div class="wrap-input">
                                <input type="text" name="einkommen" v-model="formWife.monthlyIncome"><span class="label-input" v-bind:class="{ filled : formWife.monthlyIncome.length >= 1 }">Monatliches Nettoeinkommen (optional)</span>
                            </div>
                        </div>
                        <div class="notification">Bei unbekannt bitte leer lassen</div>
                    </div>
                </div>

                <!-- Frage 6 Angaben zur Heirat & Trennung-->
                <InfoMarriage key="7" v-if="step == 7" />

                <!-- Frage 7 Letzte gemeinsame Wohnung-->
                <InfoApartment key="8" v-if="step == 8" />

                <!-- Frage 8 Gemeinsame Kinder-->
                <Children key="9" v-if="step == 9" />

                <!-- Frage 9 Ehegattenunterhalt-->
                <SpousalSupport key="10" v-if="step == 10" />

                <!-- Frage 10 Kontaktdaten-->
                <section key="11" v-if="step == 11">  
                <ValidationObserver v-slot="{ invalid }">
                    <form @submit.prevent="sendData">
                        <div class="section" key="personalData">
                            <div class="section-title">
                                <div class="author"><img src="@/assets/images/rabier.png"></div>
                                <h3><span>Schritt 10</span> Bitte geben Sie nun Ihre Kontaktdaten an.</h3>
                                <p>Bitte geben Sie nun Ihre Kontaktdaten an.</p>
                            </div>
                            <div class="form-container form-data">
                                <div class="input-row two-input">
                                    <div class="wrap-input">
                                        <ValidationProvider mode="eager" name="vorname" rules="required" v-slot="{ errors }">
                                            <input type="text" v-model="user.firstname"><span class="label-input" v-bind:class="{ filled : user.firstname.length >= 1 }">Ihr Vorname *</span>
                                            <p class="form-error">{{ errors[0] }}</p>
                                        </ValidationProvider>
                                    </div>
                                    <div class="wrap-input">
                                        <ValidationProvider mode="eager" name="nachname" rules="required" v-slot="{ errors }">
                                            <input type="text" v-model="user.name"><span class="label-input" v-bind:class="{ filled : user.name.length >= 1 }">Ihr Nachname *</span>
                                            <p class="form-error">{{ errors[0] }}</p>
                                        </ValidationProvider>
                                    </div>
                                </div>
                                <div class="input-row">
                                    <div class="wrap-input">
                                        <ValidationProvider mode="eager" name="email" rules="required|email" v-slot="{ errors }">
                                            <input type="email" v-model="user.email"><span class="label-input" v-bind:class="{ filled : user.email.length >= 1 }">Ihre E-Mail Adresse *</span>
                                            <p class="form-error">{{ errors[0] }}</p>
                                        </ValidationProvider>
                                    </div>
                                </div>
                                <div class="input-row mb-25">
                                    <div class="wrap-input">
                                        <MazPhoneNumberInput
                                            v-model="phone"
                                            type="tel"
                                            default-country-code="DE"
                                            :no-country-selector=true
                                            :only-countries="['DE']"
                                            placeholder="Ihre Telefonnummer*"
                                            @update="phonenumberResult = $event"
                                            :translations="{
                                                countrySelectorLabel: 'Land',
                                                countrySelectorError: 'Bitte Land wählen',
                                                phoneNumberLabel: 'Ihre Telefonnummer',
                                                example: 'Beispiel:'
                                            }"
                                        />
                                        <!--<ValidationProvider mode="eager" name="phone" rules="required" v-slot="{ errors }">
                                            <input type="phone" v-model="phone"><span class="label-input" v-bind:class="{ filled : phone.length >=1 }">Ihre Telefonnummer *</span>
                                            <p class="form-error">{{ errors[0] }}</p>
                                        </ValidationProvider> -->
                                    </div>
                                </div>
                                <div class="input-row">
                                    <ValidationProvider mode="eager" name="widerruf" :rules="{ required: { allowFalse: false } }" v-slot="{ errors }">
                                        <input class="checkbox" type="checkbox" name="checkbox1" value="checked" v-model="widerruf" checked id="checkbox-1"><span></span>
                                        <label for="checkbox-1">Ich habe die <router-link to="/widerruf">Widerrufsbelehrung</router-link> gelesen und möchte, dass mit der Ausführung meines Auftrages sofort begonnen wird</label>
                                        <p class="form-error">{{ errors[0] }}</p>
                                    </ValidationProvider>
                                    
                                </div>
                                <div class="input-row mb-25">
                                    <ValidationProvider mode="eager" name="beratung" :rules="{ required: { allowFalse: false } }" v-slot="{ errors }">
                                        <input class="checkbox" type="checkbox" name="checkbox2" value="checked" v-model="beratung" id="checkbox-2"><span></span>
                                        <label for="checkbox-2">Ich verzichte auf eine ausführliche anwaltliche Beratung vor Einreichung des Scheidungsantrages, damit der Antrag sofort eingereicht werden kann</label>
                                        <p class="form-error">{{ errors[0] }}</p>
                                    </ValidationProvider>
                                    
                                </div>
                                <vue-honeypot ref="honeypot" />
                                <div class="input-row">
                                    <button class="next" type="submit" :disabled="invalid == true || formSubmitted == true"><span v-if="isLoading == true"></span>Kostenpflichtig beauftragen</button>
                                </div>
                            </div>
                        </div>
                    </form>
                </ValidationObserver>
                </section>
                <!-- Result-->
                <div class="section result-section" key="12" v-if="step == 12">
                    <div class="section-title">
                        <div class="icon-document"><img src="@/assets/images/icons-check-document-active.png"></div>
                        <h3>Vielen Dank für Ihren Antrag. <br> Wie geht es jetzt weiter?</h3>
                        <p><b>Wichtig für Sie zu wissen</b> <br> <br></p>
                        <p>Wir beginnen nun mit der Bearbeitung Ihrer Scheidung. <br> <br></p>
                        <p>Sie erhalten per E-Mail eine zu unterschreibende Vollmacht. Bitte überprüfen Sie in den nächsten Minuten Ihr Postfach. Die Unterzeichnung können Sie auch digital am Smartphone oder PC vornehmen,</p>
                    </div>
                </div>
            </transition>
            <div class="form-control" v-if="step < 11" key="13">
                <transition name="fade">
                    <button class="next" :disabled="checked < 1" v-if="step == 5 || step == 6 || step == 7 || step == 8" v-on:click="next()"><span v-if="isLoading == true"></span>Weiter</button>
                    <button class="next" v-if="(checked >= 1) & (step <= 4 || step == 9 || step == 10)" v-on:click="next()"><span v-if="isLoading == true"></span>Weiter</button>
                    
                </transition>
                <p v-show="step > 1"><a class="prev" href="#" v-on:click.prevent="prev()">
                    <svg>
                    <use xlink:href="@/assets/images/sprite.svg#right-arrow"></use>
                    </svg>Zurück</a>
                </p>
            </div>
        </div>
    </section>
</template>
<script>
/*eslint-disable no-useless-escape*/
import { MazPhoneNumberInput } from 'maz-ui';
import { ValidationProvider, ValidationObserver } from 'vee-validate';
import { extend } from 'vee-validate';
import { required as veeRequired } from 'vee-validate/dist/rules';
import VueHoneypot from 'vue-honeypot';

import axios from 'axios';
import { mapState, mapMutations } from 'vuex';
import { validationMixin } from 'vuelidate';
import { required } from 'vuelidate/lib/validators';
import { logger } from '@/common/logger';

// Implement own mail regex, due to not correctly identify wrong mails like 'mail@-example.de'
extend('email', value => {
    const EMAILREG = /^[a-zA-Z0-9.!#$%&'*+\/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)+$/;
    
    if (EMAILREG.test(value)) {
        return true;
    }
    return 'Bitte eine gültige E-Mail-Adresse eingeben';
});

// Override the default message.
extend('required', {
  ...veeRequired,
  message: 'Dies ist ein Pflichtfeld'
});

export default {
    mixins: [validationMixin],
    name: 'Form',
    components: {
        ValidationProvider,
        ValidationObserver,
        MazPhoneNumberInput,
        VueHoneypot,
        Express: () => import('./questions/Express'),
        Finance: () => import('./questions/Finance'),
        Applicant: () => import('./questions/Applicant'),
        Consent: () => import('./questions/Consent'),
        InfoMarriage: () => import('./questions/InfoMarriage'),
        InfoApartment: () => import('./questions/InfoApartment'),
        Children: () => import('./questions/Children'),
        SpousalSupport: () => import('./questions/SpousalSupport')
    },
    data() {
        return {
            progressWidth: 5,
            phone: null,
            phonenumberResult: null,
            formSubmitted: false,
            widerruf: false,
            beratung: false,
            formHusband: {
                name: '',
                firstname: '',
                streetNo: '',
                postcodeCity: '',
                nationality: '',
                monthlyIncome: ''
            },
            formWife: {
                name: '',
                firstname: '',
                streetNo: '',
                postcodeCity: '',
                nationality: '',
                monthlyIncome: ''
            },
        };
    },
    validations() {
        if (this.step == 5) {
            return {
                formHusband: {
                    name: {
                    required,
                    },
                    firstname: {
                    required
                    },
                    streetNo: {
                    required,
                    },
                    postcodeCity: {
                    required
                    },
                    nationality: {
                    required
                    }
                }
            }
        }
        if (this.step == 6) {
            return {
                formWife: {
                    name: {
                    required,
                    },
                    firstname: {
                    required
                    },
                    streetNo: {
                    required,
                    },
                    postcodeCity: {
                    required
                    },
                    nationality: {
                    required
                    }
                }
            }
        }
    },
    mounted() {
        axios.get('https://api.ipify.org/?format=json').then((response) => {
        this.ip = response.data.ip;
        });
    },
    computed: mapState({
        user: (state) => state.user,
        step: (state) => state.step,
        isLoading: (state) => state.isLoading,
        checked: (state) => state.checked,
    }),
    methods: {
        ...mapMutations([
            'incrementChecked',
            'decrementChecked',
            'setChecked',
            'setStep',
            'incrementStep',
            'decrementStep',
            'setLoading',
            'updatePhoneNumber',
            'createCaseNumber',
            'updateUserFormHusband',
            'updateUserFormWife',
            'updateUserDates'
        ]),
        prev() {
            this.decrementStep();

            this.incrementChecked();
        },
        next() {
            this.setLoading(true);
            setTimeout(
            () =>  {
                /*For the next and prev navigation and the visibility of the next button,
                we have to check if the next question is already filled. If yes, show next button.
                */
                switch (this.step) {
                        
                    // Zustimmung Ehe   
                    case 5:
                        // form validation
                        this.$v.formHusband.$touch();
                        if (this.$v.formHusband.$anyError) {
                            this.setLoading(false);
                            return;
                        }
                        this.updateUserFormHusband(this.formHusband);
                        
                        break;
                    // Geschwindigkeitsverstoß Geschwindigkeit    
                    case 6:
                        // form validation
                        this.$v.formWife.$touch();
                        if (this.$v.formWife.$anyError) {
                            this.setLoading(false);
                            return;
                        }
                        this.updateUserFormWife(this.formWife);

                        break;                
                    
                    default:
                        break;
                }
                
                this.setChecked(0);
                this.incrementStep();

                this.progressWidth = 100 / 11 * this.step; // 16 - it's count of question in form

                this.setLoading(false);
                scrollTo('body', 500, { easing: 'ease-in-out' });
            } , 500);
        },
        sendData() {
            
            if (!this.phonenumberResult.isValid) {
                return;
            }
            this.$refs.honeypot.validate();

            //gtm tracking
            if (this.$gtm.enabled()) {
                window.dataLayer?.push({
                    event: "conversion",
                    formType: "Lead",
                    formPosition: "Center"
                });
            }
            
            this.formSubmitted = true;
            this.$store.dispatch('setLoadingState', true);

            //remove non-numbers from string
            this.updatePhoneNumber(this.phone);

            //send user data to lead service and get created lead id
            axios({
                method: 'post',
                data: {
                    user: this.user
                },
                url: `${process.env.VUE_APP_API_LEADS_URL}/divorces/insert`,
                headers: {
                'Content-Type': 'application/json',
                },
            }).then((response) => {
                var leadId = response.data.leadId; // Extract the leadId string
                console.log('Received leadId:', leadId);

                if (leadId != '') {
                    //redirect to Vollmacht Page
                    window.location= `${process.env.VUE_APP_FRONTEND_MANDATE_URL}?lid=${leadId}&pr=SE`;
                } else {
                    logger.error('Fehler bei Übertragung an Zoho. Fallnummer:{Fallnummer};Name:{Name}, {Vorname};E-Mail: {Email};Tel.: {Phone}', this.user.casenumber, this.user.name, this.user.firstname, this.user.email, this.user.phone);
                    this.setStep(12);
                    this.formSubmitted = false;
                }
                this.$store.dispatch('setLoadingState', false);
            })
            .catch((error) => {
                logger.error(error);
                logger.error('Fehler bei Übertragung an Zoho. Fallnummer:{Fallnummer};Name:{Name}, {Vorname};E-Mail: {Email};Tel.: {Phone}', this.user.casenumber, this.user.name, this.user.firstname, this.user.email, this.user.phone);
                this.$store.dispatch('setLoadingState', false);
                this.formSubmitted = false;
            });

        }
    }
}
</script>